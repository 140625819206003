"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getViewpointNavigation: function() {
        return getViewpointNavigation;
    },
    getViewpointOptionsHierarchy: function() {
        return getViewpointOptionsHierarchy;
    },
    getSingleViewpointOptionsHierarchy: function() {
        return getSingleViewpointOptionsHierarchy;
    }
});
var _lodash = require("lodash");
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
var getViewpointNavigation = function(perspectivesNavigation, perspectiveID, viewpointID) {
    var perspectiveNavigation = perspectivesNavigation.find(function(e) {
        return e.perspectiveID === perspectiveID;
    });
    var viewpointNavigation = perspectiveNavigation ? perspectiveNavigation.viewpointsNavigation.find(function(e) {
        return e.viewpointID === viewpointID;
    }) : null;
    return viewpointNavigation ? viewpointNavigation.navigation : [];
};
var formatViewpointTitle = function(param) {
    var title = param.title, draftAnnotations = param.draftAnnotations;
    switch(draftAnnotations){
        case "IN_PROGRESS":
            return "".concat(title, " (In Progress)");
        case "DEPRECATED":
            return "".concat(title, " (Deprecated)");
        default:
            return title || "";
    }
};
var createOption = function(viewpoint) {
    return {
        value: viewpoint.viewpointID,
        text: formatViewpointTitle(viewpoint),
        viewpointID: viewpoint.viewpointID,
        perspectiveID: viewpoint.perspectiveID,
        ownerID: viewpoint.ownerID,
        parent: viewpoint.parent,
        children: viewpoint.children
    };
};
var getChildViewpoints = function(viewpoints, parentViewpoint) {
    var recursionStack = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : [];
    var childViewpointData = parentViewpoint.childViewpointData || [];
    return (0, _lodash.uniqBy)(childViewpointData, "targetViewpointID").reduce(function(list, childViewpointRef) {
        var localTargetViewpoint = viewpoints.find(function(v) {
            return v.viewpointID === childViewpointRef.targetViewpointID;
        });
        if (localTargetViewpoint) {
            if (recursionStack.find(function(e) {
                return e === localTargetViewpoint.viewpointID;
            })) {
                /* avoid circular references */ return list;
            }
            var nextRecursionStack = recursionStack.concat(localTargetViewpoint.viewpointID);
            var finalChildViewpoint = _objectSpreadProps(_objectSpread({}, localTargetViewpoint), {
                parent: parentViewpoint
            });
            /* reference a child viewpoint on same perspective */ var childViewpointChildren = localTargetViewpoint.childViewpointData ? getChildViewpoints(viewpoints, finalChildViewpoint, nextRecursionStack) : [];
            finalChildViewpoint.children = childViewpointChildren;
            return list.concat([
                createOption(finalChildViewpoint)
            ]);
        }
        /* reference a child viewpoint in another perspective */ /* for now we don't show children for this child viewpoints */ return list.concat([
            createOption({
                perspectiveID: childViewpointRef.targetPerspectiveID,
                viewpointID: childViewpointRef.targetViewpointID,
                title: childViewpointRef.title,
                ownerID: viewpoints[0].ownerID,
                parent: parentViewpoint,
                children: []
            })
        ]);
    }, []);
};
var getViewpointOptionsHierarchy = function(viewpoints) {
    return viewpoints.filter(function(viewpoint) {
        return viewpoint.showOnTabs;
    }).map(function(viewpoint) {
        return createOption(viewpoint);
    });
};
var getSingleViewpointOptionsHierarchy = function(viewpoints, viewpoint) {
    return viewpoint.childViewpointData ? getChildViewpoints(viewpoints, viewpoint, [
        viewpoint.viewpointID
    ]) : [];
};
