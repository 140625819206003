"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var _viewpoints = require("../../actions/viewpoints");
var _default = function(params) {
    var props = params.props, prevProps = params.prevProps, api = params.api, sameViewpoint = params.sameViewpoint;
    var cancelled = props.viewpoint.cancelled;
    var stoppedEditing = !prevProps.viewpoint.cancelled && cancelled;
    if (sameViewpoint && stoppedEditing) {
        // must remove inserted rows once user hits cancel, thus canceling edit mode
        prevProps.viewpoint.insertedRows.forEach(function(row) {
            var node = api.getDisplayedRowAtIndex(row.index);
            api.updateRowData({
                remove: [
                    node.data
                ]
            });
        });
        props.dispatch((0, _viewpoints.setCancelled)(false));
    }
    return params;
};
