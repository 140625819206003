"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    setHierarchyContextMenuOpen: function() {
        return setHierarchyContextMenuOpen;
    },
    setHierarchyContextMenuPosition: function() {
        return setHierarchyContextMenuPosition;
    },
    setHierarchyRowData: function() {
        return setHierarchyRowData;
    },
    setHierarchyContextDetailsData: function() {
        return setHierarchyContextDetailsData;
    }
});
var setHierarchyContextMenuOpen = function(open, anchorNode) {
    return {
        type: "GRID_SET_HIERARCHY_CONTEXT_MENU_OPEN",
        open: open,
        anchorNode: anchorNode
    };
};
var setHierarchyContextMenuPosition = function(position) {
    return {
        type: "GRID_SET_HIERARCHY_CONTEXT_MENU_POSITION",
        position: position
    };
};
var setHierarchyRowData = function(data) {
    return {
        type: "GRID_SET_HIERARCHY_ROW_DATA",
        data: data
    };
};
var setHierarchyContextDetailsData = function(nodeData) {
    return {
        type: "GRID_SET_HIERARCHY_NODE_DATA",
        nodeData: nodeData
    };
};
