"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "loadLiveChatEngineType", {
    enumerable: true,
    get: function() {
        return loadLiveChatEngineType;
    }
});
var _endpoints = require("../utils/endpoints");
var loadLiveChatEngineType = function(param) {
    var clientMobileAppVersion = param.clientMobileAppVersion;
    return {
        type: "LOAD_LIVE_CHAT_ENGINE_TYPE",
        api: {
            // eslint-disable-next-line max-len
            endpoint: "".concat((0, _endpoints.baseUrl)(), "/newchat/support/liveChat/loadParams?clientMobileAppVersion=").concat(clientMobileAppVersion)
        }
    };
};
