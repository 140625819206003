"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    setWindowHeight: function() {
        return setWindowHeight;
    },
    setImpersonationHeaderHeight: function() {
        return setImpersonationHeaderHeight;
    }
});
var setWindowHeight = function(height) {
    return {
        type: "SET_WINDOW_HEIGHT",
        height: height
    };
};
var setImpersonationHeaderHeight = function(height) {
    return {
        type: "SET_IMPERSONATION_HEADER_HEIGHT",
        height: height
    };
};
