"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var _eventBuilder = require("../../../utils/eventBuilder");
var _conditionEvaluator = require("../conditionEvaluator");
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function isPinnedRowInAggregatedColumn(colDef, row) {
    return !!(colDef.aggregationFunction && row && row.isPinnedRow);
}
function getDefaultCellClasses(defaultClasses) {
    if (!defaultClasses) {
        return {};
    }
    return defaultClasses.reduce(function(accumulator, className) {
        return _objectSpreadProps(_objectSpread({}, accumulator), _defineProperty({}, className, function(payload) {
            var colDef = payload.colDef, data = payload.data;
            return !isPinnedRowInAggregatedColumn(colDef, data);
        }));
    }, {});
}
function getConditionalCellClasses(conditionalClasses, fields) {
    if (!conditionalClasses) {
        return {};
    }
    return conditionalClasses.reduce(function(accumulator, entry) {
        return _objectSpreadProps(_objectSpread({}, accumulator), _defineProperty({}, entry.className, function(payload) {
            var colDef = payload.colDef, data = payload.data, value = payload.value;
            if (value && value.editMode) {
                return false;
            }
            if (isPinnedRowInAggregatedColumn(colDef, data)) {
                return false;
            }
            var currentEvent = {
                data: (0, _eventBuilder.buildObjectFromEntireCellContents)({
                    values: payload.data,
                    fields: fields
                })
            };
            try {
                return (0, _conditionEvaluator.runConditions)(entry.condition, currentEvent);
            } catch (e) {
                return false;
            }
        }));
    }, {});
}
function getCellClassRules(style, fields) {
    var baseResult = {
        "grid-readonly-cell": function(payload) {
            if (payload.colDef.metaEditable === "Never") {
                return true;
            }
            if (payload.colDef.editable) {
                return !payload.colDef.editable({
                    colDef: payload.colDef,
                    node: payload.node
                });
            }
            return true;
        },
        "grid-error-cell": function(payload) {
            return payload.node && payload.node.errors && payload.node.errors.some(function(error) {
                return error.attributeID === payload.colDef.attributeID;
            });
        },
        "grid-edited-cell": function(payload) {
            if (payload && payload.node && payload.node.adpEditedAttributesList) {
                return payload.node.adpEditedAttributesList.findIndex(function(attributeID) {
                    return payload.colDef.attributeID === attributeID;
                }) >= 0;
            }
            return false;
        }
    };
    if (!style) {
        return baseResult;
    }
    var defaultClasses = getDefaultCellClasses(style.default);
    var conditionalClasses = getConditionalCellClasses(style.conditional, fields);
    return _objectSpread({}, baseResult, defaultClasses, conditionalClasses);
}
var _default = getCellClassRules;
