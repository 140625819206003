// Replaced with the body style added to src/grid/styles/base/_base.scss
// This was done because the javascript approach was not working for Safari,
// while the CSS solution seems to work for both Safari and Chrome
"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var _default = function(params) {
    return params;
};
