"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getPrefix: function() {
        return getPrefix;
    },
    getURLWithPrefix: function() {
        return getURLWithPrefix;
    },
    addPrefixToBaseURL: function() {
        return addPrefixToBaseURL;
    }
});
var getPrefix = function() {
    var pathnames = window.location.pathname.split("/");
    var ignoreIndex = pathnames.findIndex(function(d) {
        return d === "d";
    });
    var prefixes = pathnames.slice(0, ignoreIndex);
    var prefix = prefixes.filter(function(p) {
        return p !== "";
    }).join("/");
    return prefix;
};
var getURLWithPrefix = function(path) {
    var prefixes = getPrefix();
    return prefixes.length > 0 ? "/".concat(prefixes).concat(path) : path;
};
var addPrefixToBaseURL = function(baseUrl) {
    var prefixes = getPrefix();
    return prefixes.length > 0 ? "".concat(baseUrl, "/").concat(prefixes) : baseUrl;
};
