"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getPerspectives: function() {
        return getPerspectives;
    },
    setPerspectives: function() {
        return setPerspectives;
    },
    togglePerspectivePin: function() {
        return togglePerspectivePin;
    },
    closePerspective: function() {
        return closePerspective;
    }
});
var _endpoints = require("../utils/endpoints");
var getPerspectives = function() {
    return {
        type: "GET_PERSPECTIVES",
        api: {
            method: "GET",
            endpoint: _endpoints.perspectiveService,
            headers: {
                "Content-Type": "application/json"
            }
        }
    };
};
var setPerspectives = function(perspectives) {
    return {
        type: "SET_PERSPECTIVES",
        perspectives: perspectives
    };
};
var togglePerspectivePin = function(perspectiveID) {
    return {
        type: "TOGGLE_PERSPECTIVE_PIN",
        emitParams: {
            responseEventName: "TOGGLE_PERSPECTIVE_PIN_RESP",
            perspectiveID: perspectiveID
        }
    };
};
var closePerspective = function(perspective) {
    return {
        type: "CLOSE_PERSPECTIVE",
        emitParams: {
            responseEventName: "CLOSE_PERSPECTIVE_RESP",
            perspectiveID: perspective.perspectiveID
        }
    };
};
