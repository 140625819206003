"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    onShareClick: function() {
        return onShareClick;
    },
    onShareClose: function() {
        return onShareClose;
    },
    onShareSend: function() {
        return onShareSend;
    },
    onShareCancel: function() {
        return onShareCancel;
    },
    onShareRemovePeer: function() {
        return onShareRemovePeer;
    },
    onShareAddPeer: function() {
        return onShareAddPeer;
    },
    onShareFilterChange: function() {
        return onShareFilterChange;
    }
});
var onShareClick = function() {
    return {
        type: "GRID_SHARE_ONCLICK"
    };
};
var onShareClose = function() {
    return {
        type: "GRID_SHARE_ONCLOSE"
    };
};
var onShareSend = function() {
    return {
        type: "GRID_SHARE_ONSEND"
    };
};
var onShareCancel = function() {
    return {
        type: "GRID_SHARE_ONCANCEL"
    };
};
var onShareRemovePeer = function(id) {
    return {
        type: "GRID_SHARE_REMOVE_PEER",
        id: id
    };
};
var onShareAddPeer = function(peer) {
    return {
        type: "GRID_SHARE_ADD_PEER",
        peer: peer
    };
};
var onShareFilterChange = function(filter) {
    return {
        type: "GRID_SHARE_FILTER_CHANGE",
        filter: filter
    };
};
