"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    tittleOffscreen: function() {
        return tittleOffscreen;
    },
    default: function() {
        return _default;
    }
});
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
var tittleOffscreen = {
    top: -9999,
    left: -9999,
    arrowLeft: 1,
    arrowTop: 1,
    arrowPosition: "bottom"
};
var _default = function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        visible: false,
        content: "",
        node: null,
        position: _objectSpread({}, tittleOffscreen)
    }, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case "SHOW_DOM_TITLE":
            return _objectSpreadProps(_objectSpread({}, state), {
                visible: true,
                content: action.content,
                node: action.node,
                className: action.className
            });
        case "HIDE_DOM_TITLE":
            return _objectSpreadProps(_objectSpread({}, state), {
                visible: false,
                content: null,
                node: null,
                className: ""
            });
        case "SET_DOM_TITLE_POSITION":
            return _objectSpreadProps(_objectSpread({}, state), {
                position: action.position
            });
        default:
            return state;
    }
};
