"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var postMessageMiddleware = function(store) {
    return function(next) {
        return function(action) {
            if (action.postMessage && action.postMessage.element && action.postMessage.message) {
                var _postMessage = action.postMessage, element = _postMessage.element, message = _postMessage.message;
                var targetElement = document.querySelector(element);
                if (targetElement && targetElement.contentWindow && targetElement.contentWindow.postMessage) {
                    targetElement.contentWindow.postMessage(message, "*");
                }
                action.completed = true;
            }
            return next(action);
        };
    };
};
var _default = postMessageMiddleware;
