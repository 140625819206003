"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "rowSpanningProcessor", {
    enumerable: true,
    get: function() {
        return rowSpanningProcessor;
    }
});
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function rowSpanningProcessor(params) {
    var props = params.props, rowNodes = params.rowNodes;
    if (!props || !props.viewpoint || !props.viewpoint.meta || !props.viewpoint.meta.specialColumnsIndexes) {
        return params;
    }
    var rowSpanningIndexes = props.viewpoint.meta.specialColumnsIndexes.rowSpanningIndexes;
    if (!rowSpanningIndexes || !rowSpanningIndexes.length) {
        return params;
    }
    var rowSpanningIDs = new Set();
    rowNodes.forEach(function(node) {
        var data = node.data;
        rowSpanningIndexes.forEach(function(rowSpanningIndex) {
            var column = "column_".concat(rowSpanningIndex);
            var columnValue = node.data[column] || {};
            var rowSpanningID = "".concat(column, "||").concat(columnValue.rowSpanningID);
            var cellValue = {
                value: !node.cloneSourceDetails && rowSpanningIDs.has(rowSpanningID) ? null : columnValue.immutableValue
            };
            data = _objectSpreadProps(_objectSpread({}, data), _defineProperty({}, column, _objectSpread({}, data[column], cellValue)));
            rowSpanningIDs.add(rowSpanningID);
        });
        node.setData(data);
    });
    return params;
}
