"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "getFirstActiveViewpoint", {
    enumerable: true,
    get: function() {
        return getFirstActiveViewpoint;
    }
});
function getFirstActiveViewpoint() {
    var viewpoints = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
    return viewpoints.find(function(v) {
        return v.ordering && v.showOnTabs;
    });
}
