"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var _default = function(params) {
    var props = params.props, prevProps = params.prevProps, api = params.api;
    if (props && prevProps && prevProps.viewpoint.editable !== props.viewpoint.editable) {
        if (!props.viewpoint.editable) {
            api.stopEditing();
        }
    }
    return params;
};
