"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    baseUrl: function() {
        return baseUrl;
    },
    httpServerUrl: function() {
        return httpServerUrl;
    },
    socketServerUrl: function() {
        return socketServerUrl;
    },
    socketServerPath: function() {
        return socketServerPath;
    },
    getMobileServerUrl: function() {
        return getMobileServerUrl;
    },
    chatUrl: function() {
        return chatUrl;
    },
    relayAPI: function() {
        return relayAPI;
    },
    generateFacebookSubscriptionEndpoint: function() {
        return generateFacebookSubscriptionEndpoint;
    },
    generateSlackSubscriptionEndpoint: function() {
        return generateSlackSubscriptionEndpoint;
    },
    eLoopsUrl: function() {
        return eLoopsUrl;
    },
    practitionerServicesURL: function() {
        return practitionerServicesURL;
    },
    timelineServicesURL: function() {
        return timelineServicesURL;
    },
    peopleServicesURL: function() {
        return peopleServicesURL;
    },
    userServicesURL: function() {
        return userServicesURL;
    },
    viewpointService: function() {
        return viewpointService;
    },
    perspectiveService: function() {
        return perspectiveService;
    },
    eventFlowEndpoint: function() {
        return eventFlowEndpoint;
    }
});
var _prefix = require("./Prefix");
var getBaseUrl = function() {
    return [
        document.location.protocol,
        "//",
        document.location.hostname
    ].join("");
};
var baseUrl = function() {
    return (0, _prefix.addPrefixToBaseURL)(getBaseUrl());
};
var httpServerUrl = function() {
    var base = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : baseUrl();
    return base;
};
var socketServerUrl = function() {
    var base = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : getBaseUrl();
    return base.replace("//e.", "//ws.").replace("//roll", "//ws");
};
var socketServerPath = function() {
    var path = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : (0, _prefix.getPrefix)();
    return path ? "/".concat(path, "/socket.io/") : "";
};
var getMobileServerUrl = function() {
    var base = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : baseUrl();
    return "".concat(base, "/m/");
};
var chatUrl = function() {
    var base = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : baseUrl();
    return [
        base,
        "/chatserver/direct/e"
    ].join("");
};
var relayAPI = function() {
    var base = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : baseUrl();
    return "".concat(base, "/newchat/relay/v1");
};
var generateFacebookSubscriptionEndpoint = function() {
    if (document.location.hostname.indexOf(".fit.") > 0) {
        return "https://m.me/adpebotfit";
    }
    return "https://m.me/adpebot";
};
var generateSlackSubscriptionEndpoint = function() {
    var base = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : baseUrl();
    return "".concat(base, "/newchat/login/slack");
};
var eLoopsUrl = function() {
    var base = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : baseUrl();
    return "".concat(base, "/loops");
};
var practitionerServicesURL = "".concat(socketServerUrl()).concat((0, _prefix.getURLWithPrefix)("/practitionerServices"));
var timelineServicesURL = "".concat(socketServerUrl()).concat((0, _prefix.getURLWithPrefix)("/timeline"));
var peopleServicesURL = "".concat(socketServerUrl()).concat((0, _prefix.getURLWithPrefix)("/people"));
var userServicesURL = "".concat(socketServerUrl()).concat((0, _prefix.getURLWithPrefix)("/user"));
var viewpointService = "".concat(practitionerServicesURL, "/v1/viewpoint");
var perspectiveService = "".concat(practitionerServicesURL, "/v1/perspectives");
var eventFlowEndpoint = function() {
    var eventFlowPath = "eventflow/";
    if (document.location.hostname.indexOf(".dit.") > 0) return "https://internal-us-east-1.dit.adpeai.com/".concat(eventFlowPath);
    if (document.location.hostname.indexOf(".fit.") > 0) return "https://internal-us-east-1.fit.adpeai.com/".concat(eventFlowPath);
    if (document.location.hostname.indexOf(".hf.") > 0) return "https://internal-us-east-1.hf.adpeai.com/".concat(eventFlowPath);
    if (document.location.hostname.indexOf(".sb.") > 0) return "https://internal-us-east-1.sb.adp.ai/".concat(eventFlowPath);
    if (document.location.hostname.indexOf(".stg.") > 0) return "https://internal-us-east-1.stg.adp.ai/".concat(eventFlowPath);
    return "https://eventflow-us-east-1.local.adp.ai/".concat(eventFlowPath);
};
