"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getEditorOptions: function() {
        return getEditorOptions;
    },
    setEditorOptions: function() {
        return setEditorOptions;
    },
    clearEditorOptions: function() {
        return clearEditorOptions;
    },
    setEditorPosition: function() {
        return setEditorPosition;
    }
});
var _endpoints = require("../utils/endpoints");
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};
    var target = _objectWithoutPropertiesLoose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
var viewpointService = "".concat(_endpoints.practitionerServicesURL, "/v1/viewpoint");
var getEditorOptions = function(_param) {
    var viewpointID = _param.viewpointID, viewpointAttributeMappingID = _param.viewpointAttributeMappingID, schemeCode = _param.schemeCode, otherParameters = _objectWithoutProperties(_param, [
        "viewpointID",
        "viewpointAttributeMappingID",
        "schemeCode"
    ]);
    return {
        type: "GET_EDITOR_OPTIONS",
        api: {
            method: "POST",
            endpoint: "".concat(viewpointService, "/editor?viewpointID=").concat(viewpointID),
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(_objectSpread({
                schemeCode: schemeCode,
                viewpointAttributeMappingID: viewpointAttributeMappingID
            }, otherParameters))
        }
    };
};
var setEditorOptions = function(options) {
    return {
        type: "SET_EDITOR_OPTIONS",
        options: options
    };
};
var clearEditorOptions = function() {
    return {
        type: "CLEAR_EDITOR_OPTIONS"
    };
};
var setEditorPosition = function(position, isOffScreen) {
    return {
        type: "SET_EDITOR_POSITION",
        position: position,
        isOffScreen: isOffScreen
    };
};
