"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
var _default = function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        viewpointID: "",
        insights: []
    }, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case "GET_CHILD_VIEWPOINT":
        case "GET_FILTERED_VIEWPOINT":
        case "GET_VIEWPOINT":
            {
                if (action.fetching || action.error) {
                    return _objectSpreadProps(_objectSpread({}, state), {
                        insights: []
                    });
                }
                return _objectSpreadProps(_objectSpread({}, state), {
                    viewpointID: action.viewpointID,
                    insights: action.response.insights
                });
            }
        case "GET_INSIGHTS":
            {
                if (action.fetching || action.error) {
                    return state;
                }
                var _response = action.response, viewpointID = _response.viewpointID, insights = _response.insights;
                if (viewpointID !== state.viewpointID) {
                    return state;
                }
                return _objectSpreadProps(_objectSpread({}, state), {
                    insights: insights
                });
            }
        default:
            return state;
    }
};
