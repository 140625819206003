"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    DEFAULT_COLUMN_WIDTH: function() {
        return DEFAULT_COLUMN_WIDTH;
    },
    CENTER_ALIGNED_CLASS: function() {
        return CENTER_ALIGNED_CLASS;
    },
    LEFT_ALIGNED_CLASS: function() {
        return LEFT_ALIGNED_CLASS;
    },
    RIGHT_ALIGNED_CLASS: function() {
        return RIGHT_ALIGNED_CLASS;
    }
});
var DEFAULT_COLUMN_WIDTH = 70;
var CENTER_ALIGNED_CLASS = "ag-header-cell-center-aligned";
var LEFT_ALIGNED_CLASS = "ag-header-cell-left-aligned";
var RIGHT_ALIGNED_CLASS = "ag-header-cell-right-aligned";
