"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    positionOffscreen: function() {
        return positionOffscreen;
    },
    getCellMenuPosition: function() {
        return getCellMenuPosition;
    },
    getTitlePosition: function() {
        return getTitlePosition;
    },
    mapActionItem: function() {
        return mapActionItem;
    },
    dropChartActions: function() {
        return dropChartActions;
    },
    findChartActions: function() {
        return findChartActions;
    },
    mapRowData: function() {
        return mapRowData;
    },
    filterExpression: function() {
        return filterExpression;
    },
    filterMultiRowExpression: function() {
        return filterMultiRowExpression;
    },
    isChildOf: function() {
        return isChildOf;
    },
    isChildOfByClassName: function() {
        return isChildOfByClassName;
    }
});
var _lodash = require("lodash");
var _conditionEvaluator = require("./conditionEvaluator");
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
var getWindowSize = function() {
    return {
        height: window.innerHeight,
        width: window.innerWidth
    };
};
var getCoords = function(node) {
    if (node && node.isSyntheticNode) {
        return {
            top: node.top,
            left: node.left,
            height: 0,
            width: 0
        };
    }
    return node && node.getBoundingClientRect();
};
var positionOffscreen = {
    popupTop: -9999,
    popupLeft: -9999,
    arrowPosition: "left",
    arrowTop: 30
};
var getCellMenuPosition = function(popupNode, focusedCell) {
    var windowSize = getWindowSize();
    var popupCoords = getCoords(popupNode && popupNode.current);
    var cellCoords = getCoords(focusedCell);
    var popupWidth = popupCoords && popupCoords.width || 0;
    var popupHeight = popupCoords && popupCoords.height || 0;
    var popupHalfHeight = Math.trunc(popupHeight * 0.5);
    var popupTop = cellCoords.top - popupHalfHeight;
    var popupLeft = cellCoords.left + cellCoords.width + 5;
    var arrowTop = popupHalfHeight;
    var arrowPosition = "left";
    if (popupLeft + popupWidth > windowSize.width) {
        popupLeft = cellCoords.left - (popupWidth + 5);
        arrowPosition = "right";
    }
    if (popupTop + popupHeight > windowSize.height) {
        popupTop = cellCoords.top - (popupHeight - cellCoords.height);
        arrowTop = popupHeight - 25;
    }
    if (popupTop < 0) {
        popupTop = 10;
        arrowTop = cellCoords.top - 10;
    }
    return {
        popupTop: popupTop,
        popupLeft: popupLeft,
        arrowTop: arrowTop,
        arrowPosition: arrowPosition
    };
};
var getTitlePosition = function(popupNode, node) {
    // const windowSize = getWindowSize();
    var popupCoords = getCoords(popupNode);
    var nodeCoords = getCoords(node);
    var popupWidth = popupCoords.width, popupHeight = popupCoords.height;
    var nodeWidth = nodeCoords.width, // height: nodeHeight,
    nodeLeft = nodeCoords.left, nodeTop = nodeCoords.top;
    var arrowHeight = 7;
    var arrowWidth = 7;
    var popupCenterX = Math.trunc(popupWidth * 0.5);
    var nodeCenterX = Math.trunc(nodeWidth * 0.5);
    var popupTop = nodeTop - popupHeight - arrowHeight;
    var popupLeft = nodeLeft + (nodeCenterX - popupCenterX);
    var arrowLeft = (popupLeft < 0 ? nodeCenterX : popupCenterX) - arrowWidth;
    var arrowTop = popupHeight;
    var arrowPosition = "bottom";
    return {
        top: popupTop,
        left: popupLeft < 0 ? 0 : popupLeft,
        arrowLeft: arrowLeft,
        arrowTop: arrowTop,
        arrowPosition: arrowPosition
    };
};
var mapActionItem = function(action) {
    var text = action.title || action.text || action.label || "undefined";
    var key = action.type || text || "undefined";
    return {
        type: action.type,
        key: action.key ? action.key : "".concat(action.callType, "_").concat(key, "_").concat(action.index || 0),
        text: text,
        disabled: Boolean(action.disabled),
        className: action.className || "",
        onMouseEnter: action.onMouseEnter,
        onMouseLeave: action.onMouseLeave,
        subMenu: action.subMenu || null,
        srcItem: _objectSpread({}, action)
    };
};
var dropChartActions = function(list) {
    return list.filter(function(e) {
        return e.srcItem.callType !== "chart";
    });
};
var findChartActions = function(list) {
    return list.filter(function(e) {
        return e.srcItem.callType === "chart";
    });
};
var mapRowData = function(columns, data) {
    return (0, _lodash.flatten)(columns.map(function(column) {
        return column.children || column;
    })).filter(function(param) {
        var attributeID = param.attributeID, field = param.field;
        return attributeID && field;
    }).reduce(function(prev, param) {
        var attributeID = param.attributeID, field = param.field;
        return (0, _lodash.set)(prev, attributeID, data[field]);
    }, {});
};
var filterExpression = function(expression, rowData) {
    try {
        return (0, _lodash.isEmpty)(expression) || (0, _conditionEvaluator.runConditions)(expression, {
            data: rowData
        });
    } catch (error) {
        return false;
    }
};
function filterRows(expression, rows) {
    var localExpression = (0, _lodash.omit)(expression, [
        "exists",
        "allFilteredRows"
    ]);
    if (expression.exists) {
        return rows.some(function(rowData) {
            return filterExpression(localExpression, rowData);
        });
    }
    if (!rows.length) {
        return false;
    }
    return rows.every(function(rowData) {
        return filterExpression(localExpression, rowData);
    });
}
var filterMultiRowExpression = function(expression, columns, gridApi) {
    if (expression && expression.allRows) {
        var allRows = [];
        gridApi.forEachNode(function(node) {
            var rowData = mapRowData(columns, node.data);
            allRows.push(rowData);
        });
        return filterRows(expression, allRows);
    }
    if (expression && expression.allFilteredRows) {
        var allFilteredRows = [];
        gridApi.forEachNodeAfterFilterAndSort(function(node) {
            var rowData = mapRowData(columns, node.data);
            allFilteredRows.push(rowData);
        });
        return filterRows(expression, allFilteredRows);
    }
    return true;
};
function isChildOf(node, parentNode) {
    var limit = 100;
    var n = 0;
    var currentNode = node;
    while(n < limit && currentNode && currentNode.tagName && currentNode.tagName.toUpperCase() !== "BODY"){
        if (currentNode === parentNode) {
            return true;
        }
        n += 1;
        currentNode = currentNode.parentElement;
    }
    return false;
}
function isChildOfByClassName(node, className) {
    var limit = 100;
    var n = 0;
    var currentNode = node;
    while(n < limit && currentNode && currentNode.tagName && currentNode.tagName.toUpperCase() !== "BODY"){
        if (currentNode.classList.contains(className)) {
            return true;
        }
        n += 1;
        currentNode = currentNode.parentElement;
    }
    return false;
}
