"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    injectRowsDiffHandlers: function() {
        return injectRowsDiffHandlers;
    },
    injectGetQuickFilterText: function() {
        return injectGetQuickFilterText;
    },
    resetRowsDiffSortIndex: function() {
        return resetRowsDiffSortIndex;
    },
    getRowsDiffSortIndex: function() {
        return getRowsDiffSortIndex;
    },
    getColumnValue: function() {
        return getColumnValue;
    }
});
var _common = require("../columns/common");
var _rowsDiffCalculator = require("./rowsDiffCalculator");
var sortIndex = [];
var getColumnValue = function(value) {
    if (value && typeof value === "object" && value.toString) {
        value = value.toString();
    }
    if (value === null) {
        value = "";
    }
    return value;
};
var resetRowsDiffSortIndex = function() {
    sortIndex.length = 0;
};
var getRowsDiffSortIndex = function() {
    return sortIndex;
};
var comparator = function(pValueA, pValueB, nodeA, nodeB, isInverted, colDef, nextComparator) {
    var rowsDiffMode = (0, _rowsDiffCalculator.getRowsComparisonMode)();
    if (nodeA && nodeB && rowsDiffMode !== "NONE" && sortIndex.length > 0) {
        var rowIdA = nodeA.data.isDiffRow ? nodeA.data.compareRowId : nodeA.id;
        var rowIdB = nodeB.data.isDiffRow ? nodeB.data.compareRowId : nodeB.id;
        var indexA = sortIndex.indexOf(rowIdA);
        var indexB = sortIndex.indexOf(rowIdB);
        return isInverted ? indexB - indexA : indexA - indexB;
    }
    var valueA = getColumnValue(pValueA);
    var valueB = getColumnValue(pValueB);
    if (nextComparator) {
        return nextComparator(pValueA, pValueB, nodeA, nodeB, isInverted);
    }
    if ((0, _common.isNumericColumn)(colDef)) {
        return valueA - valueB;
    }
    if (valueA) {
        if (valueA === valueB) {
            var idA = parseInt(nodeA.id, 10);
            var idB = parseInt(nodeB.id, 10);
            if (idA > idB) {
                return 1;
            }
            return -1;
        }
        return valueA && valueA.localeCompare(valueB);
    }
    return -1;
};
var getQuickFilterText = function(params, colDef, nextGetQuickFilterText) {
    if (params.data.isDiffRow) {
        var comparedValues = params.data.comparedValues;
        if (!comparedValues[colDef.field]) {
            return "";
        }
        var currentValue = comparedValues[colDef.field].currentValue;
        var nextValue = comparedValues[colDef.field].nextValue;
        return [
            currentValue,
            nextValue
        ].join("_");
    }
    if (nextGetQuickFilterText) {
        return nextGetQuickFilterText(params);
    }
    return params.value;
};
var injectRowsDiffComparator = function(colDef, nextComparator) {
    return function(valueA, valueB, nodeA, nodeB, isInverted) {
        return comparator(valueA, valueB, nodeA, nodeB, isInverted, colDef, nextComparator);
    };
};
var injectGetQuickFilterText = function(colDef, nextGetQuickFilterText) {
    return function(params) {
        return getQuickFilterText(params, colDef, nextGetQuickFilterText);
    };
};
var injectRowsDiffHandlers = function(colDef) {
    colDef.comparator = injectRowsDiffComparator(colDef, colDef.comparator);
    colDef.getQuickFilterText = injectGetQuickFilterText(colDef, colDef.getQuickFilterText);
    return colDef;
};
