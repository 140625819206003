"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    openSlider: function() {
        return openSlider;
    },
    expandSlider: function() {
        return expandSlider;
    },
    updateLoadingPerspectiveStatus: function() {
        return updateLoadingPerspectiveStatus;
    },
    updateRightSidebarWidth: function() {
        return updateRightSidebarWidth;
    },
    openCPARightSidebar: function() {
        return openCPARightSidebar;
    },
    closeCPARightSidebar: function() {
        return closeCPARightSidebar;
    }
});
var openSlider = function(item, side) {
    var toggle = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
    return {
        type: "OPEN_SLIDER",
        item: item,
        side: side,
        toggle: toggle
    };
};
var expandSlider = function(side) {
    return {
        type: "EXPAND_SLIDER",
        side: side
    };
};
var updateLoadingPerspectiveStatus = function(param) {
    var isLoading = param.isLoading, isShared = param.isShared;
    return {
        type: "SIDEBAR_LOADING_PERSPECTIVES",
        isLoading: isLoading,
        isShared: isShared
    };
};
var updateRightSidebarWidth = function(rightSidebarWidth) {
    return {
        type: "RIGHT_SIDEBAR_WIDTH",
        rightSidebarWidth: rightSidebarWidth
    };
};
var openCPARightSidebar = function() {
    return {
        type: "OPEN_CPA_RIGHT_SIDEBAR"
    };
};
var closeCPARightSidebar = function() {
    return {
        type: "CLOSE_CPA_RIGHT_SIDEBAR"
    };
};
