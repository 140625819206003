"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getCellValue: function() {
        return getCellValue;
    },
    getNumberLocaleCellValue: function() {
        return getNumberLocaleCellValue;
    }
});
var getCellValue = function(params) {
    if (!params) {
        return "";
    }
    var value = params.value;
    return value && value.hasOwnProperty("value") && value.value !== null ? value.value : "";
};
var getNumberLocaleCellValue = function(params) {
    var cellValue = getCellValue(params);
    return cellValue !== "" && params.value && params.value.locale ? cellValue.toLocaleString(params.value.locale) : cellValue;
};
