"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getSharedViewpoint: function() {
        return getSharedViewpoint;
    },
    getSharedViewpoints: function() {
        return getSharedViewpoints;
    },
    loadSharedViewpoints: function() {
        return loadSharedViewpoints;
    },
    showSharedViewPoints: function() {
        return showSharedViewPoints;
    },
    setSharedViewpoints: function() {
        return setSharedViewpoints;
    },
    cleanUnviewedViewpointsList: function() {
        return cleanUnviewedViewpointsList;
    },
    messageUser: function() {
        return messageUser;
    },
    requestSharedViewpointScreenPosition: function() {
        return requestSharedViewpointScreenPosition;
    },
    receiveSharedViewpointScreenPosition: function() {
        return receiveSharedViewpointScreenPosition;
    },
    setSharedViewpointDetails: function() {
        return setSharedViewpointDetails;
    },
    setSharedViewpointPeers: function() {
        return setSharedViewpointPeers;
    },
    setNewlySharedViewpoint: function() {
        return setNewlySharedViewpoint;
    },
    removeSharedViewpointPeers: function() {
        return removeSharedViewpointPeers;
    },
    displayHistory: function() {
        return displayHistory;
    },
    hideHistory: function() {
        return hideHistory;
    },
    updateSharedViewpointDetails: function() {
        return updateSharedViewpointDetails;
    },
    setPinnedRows: function() {
        return setPinnedRows;
    },
    removeSharedViewpointDetails: function() {
        return removeSharedViewpointDetails;
    },
    setRemovedViewpointDetails: function() {
        return setRemovedViewpointDetails;
    },
    cleanSharedViewpointDetails: function() {
        return cleanSharedViewpointDetails;
    },
    setHasSharedViewpointDetails: function() {
        return setHasSharedViewpointDetails;
    },
    clearSharingStopStatus: function() {
        return clearSharingStopStatus;
    }
});
var _endpoints = require("../utils/endpoints");
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
var getPerspective = function(viewpointID, ownerID) {
    return "".concat(_endpoints.practitionerServicesURL, "/v1/sharedViewpoint?viewpointID=").concat(viewpointID, "&ownerID=").concat(ownerID);
};
var getSharedViewpoint = function(viewpointID, ownerID) {
    return {
        type: "GET_SHARED_VIEWPOINT",
        api: {
            method: "GET",
            endpoint: getPerspective(viewpointID, ownerID)
        }
    };
};
var getSharedViewpoints = function() {
    return {
        type: "GET_SHARED_VIEWPOINTS",
        emitParams: {
            responseEventName: "GET_SHARED_VIEWPOINTS_RESP"
        }
    };
};
var loadSharedViewpoints = function() {
    return {
        type: "GET_SHARED_VIEWPOINTS",
        emitParams: {
            responseEventName: "SHOW_SHARED_VIEWPOINTS_RESP"
        }
    };
};
var showSharedViewPoints = function(sharedViewpointDetails) {
    return {
        type: "SHOW_SHARED_VIEWPOINTS",
        sharedViewpointDetails: sharedViewpointDetails
    };
};
var setSharedViewpoints = function(sharedViewpoints) {
    return {
        type: "SET_SHARED_VIEWPOINTS",
        sharedViewpoints: sharedViewpoints
    };
};
var cleanUnviewedViewpointsList = function() {
    return {
        type: "CLEAR_UNVIEWED_VIEWPOINTS_LIST"
    };
};
var messageUser = function(personID, payload) {
    return {
        type: "MESSAGE_USER",
        emitParams: {
            personID: personID,
            payload: payload
        }
    };
};
var requestSharedViewpointScreenPosition = function(request) {
    return {
        type: "REQUEST_SHARED_VIEWPOINT_SCREEN_POSITION",
        request: request
    };
};
var receiveSharedViewpointScreenPosition = function(request) {
    return {
        type: "RECEIVE_SHARED_VIEWPOINT_SCREEN_POSITION",
        request: request
    };
};
var setSharedViewpointDetails = function(sharedViewpointDetails) {
    return {
        type: "SET_SHARED_VIEWPOINT_DETAILS",
        sharedViewpointDetails: sharedViewpointDetails
    };
};
var setSharedViewpointPeers = function(sharedViewpointDetails) {
    return {
        type: "SET_SHARED_VIEWPOINT_PEERS",
        sharedViewpointDetails: sharedViewpointDetails
    };
};
var setNewlySharedViewpoint = function(sharedViewpointDetails) {
    return {
        type: "SET_NEWLY_SHARED_VIEWPOINT",
        sharedViewpointDetails: sharedViewpointDetails
    };
};
var removeSharedViewpointPeers = function(sharedViewpointDetails) {
    return {
        type: "REMOVE_SHARED_VIEWPOINT_PEERS",
        sharedViewpointDetails: sharedViewpointDetails
    };
};
var displayHistory = function(column, sharedViewpointID) {
    return {
        type: "SHARED_VIEWPOINT_DISPLAY_HISTORY",
        column: column,
        sharedViewpointID: sharedViewpointID
    };
};
var hideHistory = function(column, sharedViewpointID) {
    return {
        type: "SHARED_VIEWPOINT_HIDE_HISTORY",
        column: column,
        sharedViewpointID: sharedViewpointID
    };
};
var updateSharedViewpointDetails = function(payload) {
    return _objectSpread({
        type: "UPDATE_SHARED_VIEWPOINT_DETAILS"
    }, payload);
};
var setPinnedRows = function(pinnedRows, sharedViewpointID) {
    return {
        type: "SHARED_VIEWPOINT_SET_PINNED_ROWS",
        pinnedRows: pinnedRows,
        sharedViewpointID: sharedViewpointID
    };
};
var removeSharedViewpointDetails = function(viewpointID, sharedViewpointDetails) {
    return {
        type: "REMOVE_SHARED_VIEWPOINT_DETAILS",
        sharedViewpointDetails: sharedViewpointDetails
    };
};
var setRemovedViewpointDetails = function(removed) {
    return {
        type: "SET_REMOVED_VIEWPOINT_DETAILS",
        removed: removed
    };
};
var cleanSharedViewpointDetails = function(sharedViewpointDetails, removedDetails) {
    return {
        type: "CLEAN_SHARED_VIEWPOINT_DETAILS",
        sharedViewpointDetails: sharedViewpointDetails,
        removedDetails: removedDetails
    };
};
var setHasSharedViewpointDetails = function(hasSharedViewpointDetails) {
    return {
        type: "SHARED_VIEWPOINT_SET_HAS_SHARED_VIEWPOINT_DETAILS",
        hasSharedViewpointDetails: hasSharedViewpointDetails
    };
};
var clearSharingStopStatus = function() {
    return {
        type: "CLEAR_SHARING_STOP_STATUS"
    };
};
