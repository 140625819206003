"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    openContextMenu: function() {
        return openContextMenu;
    },
    toggleContextMenu: function() {
        return toggleContextMenu;
    },
    closeContextMenu: function() {
        return closeContextMenu;
    },
    setContextMenuPosition: function() {
        return setContextMenuPosition;
    },
    setupSubMenuTimer: function() {
        return setupSubMenuTimer;
    },
    clearSubMenuTimer: function() {
        return clearSubMenuTimer;
    }
});
var openContextMenu = function(param) {
    var menuID = param.menuID, _parentMenuID = param.parentMenuID, parentMenuID = _parentMenuID === void 0 ? null : _parentMenuID, anchorNode = param.anchorNode, component = param.component;
    return {
        type: "GRID_CONTEXT_MENU_OPEN",
        menuID: menuID,
        parentMenuID: parentMenuID,
        anchorNode: anchorNode,
        component: component
    };
};
var toggleContextMenu = function(param) {
    var menuID = param.menuID, _parentMenuID = param.parentMenuID, parentMenuID = _parentMenuID === void 0 ? null : _parentMenuID, anchorNode = param.anchorNode, component = param.component;
    return {
        type: "GRID_CONTEXT_MENU_TOGGLE",
        menuID: menuID,
        parentMenuID: parentMenuID,
        anchorNode: anchorNode,
        component: component
    };
};
var closeContextMenu = function(menuID) {
    return {
        type: "GRID_CONTEXT_MENU_CLOSE",
        menuID: menuID
    };
};
var setContextMenuPosition = function(menuID, position) {
    return {
        type: "GRID_CONTEXT_MENU_SET_POSITION",
        menuID: menuID,
        position: position
    };
};
var setupSubMenuTimer = function(param) {
    var subMenuActionID = param.subMenuActionID, menuID = param.menuID, _open = param.open, open = _open === void 0 ? true : _open, _clear = param.clear, clear = _clear === void 0 ? false : _clear, timeoutMS = param.timeoutMS;
    return {
        type: "GRID_CONTEXT_MENU_SETUP_SUBMENU_TIMER",
        subMenuActionID: subMenuActionID,
        menuID: menuID,
        open: open,
        clear: clear,
        timeoutMS: timeoutMS
    };
};
var clearSubMenuTimer = function(param) {
    var _subMenuActionID = param.subMenuActionID, subMenuActionID = _subMenuActionID === void 0 ? null : _subMenuActionID, menuID = param.menuID;
    return {
        type: "GRID_CONTEXT_MENU_CLEAR_SUBMENU_TIMER",
        subMenuActionID: subMenuActionID,
        menuID: menuID
    };
};
