"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    SIDEBAR_MIN_WIDTH: function() {
        return SIDEBAR_MIN_WIDTH;
    },
    PARTNER_PORTAL_VIEWPOINT: function() {
        return PARTNER_PORTAL_VIEWPOINT;
    },
    LOGIN_URL: function() {
        return LOGIN_URL;
    },
    LOGIN_FORM_URL: function() {
        return LOGIN_FORM_URL;
    }
});
var SIDEBAR_MIN_WIDTH = 370;
var PARTNER_PORTAL_VIEWPOINT = "partnerPortalViewpoint.read";
var LOGIN_URL = "/login";
var LOGIN_FORM_URL = "/loginform";
