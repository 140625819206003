"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    showTitle: function() {
        return showTitle;
    },
    hideTitle: function() {
        return hideTitle;
    },
    setPosition: function() {
        return setPosition;
    }
});
var showTitle = function(content, node, className) {
    return {
        type: "SHOW_DOM_TITLE",
        content: content,
        node: node,
        className: className
    };
};
var hideTitle = function() {
    return {
        type: "HIDE_DOM_TITLE"
    };
};
var setPosition = function(position) {
    return {
        type: "SET_DOM_TITLE_POSITION",
        position: position
    };
};
