"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "saveWorkspaceSession", {
    enumerable: true,
    get: function() {
        return saveWorkspaceSession;
    }
});
var _endpoints = require("../utils/endpoints");
var saveWorkspaceSession = function(workspaceSession) {
    return {
        type: "SAVE_WORKSPACE_SESSION",
        workspaceSession: workspaceSession,
        api: {
            method: "POST",
            endpoint: "".concat(_endpoints.practitionerServicesURL, "/v1/workspace/saveSession"),
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(workspaceSession)
        }
    };
};
