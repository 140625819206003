"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
var initialState = {
    rightActiveSlider: {
        item: "",
        expanded: false
    },
    leftActiveSlider: {
        item: "",
        expanded: false
    },
    isAwaitingPerspectivesData: false,
    isAwaitingSharedPerspectivesData: false,
    loadedPerspectivesType: "PRIVATE",
    perspectivesLoaded: false,
    rightSidebarWidth: 320,
    isPartnerView: false
};
var _default = function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : initialState, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case "OPEN_SLIDER":
            {
                var leftActiveSlider = {};
                var rightActiveSlider = {};
                var close;
                if (action.side === "left") {
                    close = action.toggle && action.item === state.leftActiveSlider.item;
                    leftActiveSlider.item = close ? "" : action.item;
                    leftActiveSlider.expanded = false;
                    rightActiveSlider = state.rightActiveSlider;
                }
                if (action.side === "right") {
                    close = action.toggle && action.item === state.rightActiveSlider.item;
                    rightActiveSlider.item = close ? "" : action.item;
                    rightActiveSlider.expanded = false;
                    leftActiveSlider = state.leftActiveSlider;
                }
                return _objectSpreadProps(_objectSpread({}, state), {
                    rightActiveSlider: rightActiveSlider,
                    leftActiveSlider: leftActiveSlider
                });
            }
        case "EXPAND_SLIDER":
            {
                var leftActiveSlider1 = {};
                var rightActiveSlider1 = {};
                if (action.side === "left") {
                    leftActiveSlider1.item = state.leftActiveSlider.item;
                    leftActiveSlider1.expanded = !state.leftActiveSlider.expanded;
                    rightActiveSlider1 = state.rightActiveSlider;
                }
                if (action.side === "right") {
                    rightActiveSlider1.item = state.rightActiveSlider.item;
                    rightActiveSlider1.expanded = !state.rightActiveSlider.expanded;
                    leftActiveSlider1 = state.leftActiveSlider;
                }
                return _objectSpreadProps(_objectSpread({}, state), {
                    rightActiveSlider: rightActiveSlider1,
                    leftActiveSlider: leftActiveSlider1
                });
            }
        case "SIDEBAR_LOADING_PERSPECTIVES":
            return _objectSpreadProps(_objectSpread({}, state), {
                isAwaitingPerspectivesData: action.isLoading,
                isAwaitingSharedPerspectivesData: action.isShared,
                loadedPerspectivesType: action.isShared ? "SHARED" : "PRIVATE",
                perspectivesLoaded: false
            });
        case "SHOW_SHARED_VIEWPOINTS":
            return _objectSpreadProps(_objectSpread({}, state), {
                isAwaitingPerspectivesData: false,
                isAwaitingSharedPerspectivesData: false,
                perspectivesLoaded: true
            });
        case "GET_PERSPECTIVES":
            {
                if (action.error || action.fetching) {
                    return state;
                }
                return _objectSpreadProps(_objectSpread({}, state), {
                    isAwaitingPerspectivesData: false,
                    isAwaitingSharedPerspectivesData: false,
                    perspectivesLoaded: true
                });
            }
        case "RIGHT_SIDEBAR_WIDTH":
            return _objectSpreadProps(_objectSpread({}, state), {
                rightSidebarWidth: action.rightSidebarWidth
            });
        case "OPEN_CPA_RIGHT_SIDEBAR":
            return _objectSpreadProps(_objectSpread({}, state), {
                isPartnerView: true
            });
        case "CLOSE_CPA_RIGHT_SIDEBAR":
            return _objectSpreadProps(_objectSpread({}, state), {
                isPartnerView: false,
                rightActiveSlider: {
                    item: "",
                    expanded: false
                }
            });
        default:
            return state;
    }
};
