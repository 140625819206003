"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var _sidebar = require("../../actions/sidebar");
var _default = function(params) {
    var props = params.props;
    if (props.conversation && props.conversation.conversationStarted) {
        props.dispatch((0, _sidebar.openSlider)("Chat", "right", false));
    }
    return params;
};
