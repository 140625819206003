"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var _maps = require("../utils/sharedViewpoints/maps");
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
var compareFn = function(perspective1, perspective2) {
    if (perspective1.pin === perspective2.pin) {
        return perspective1.index < perspective2.index ? -1 : 1;
    }
    return perspective1.pin && !perspective2.pin ? -1 : 1;
};
var transformFn = function(state, perspectiveID, changes) {
    return state.map(function(item) {
        if (item.perspectiveID === perspectiveID) {
            return _objectSpread({}, item, changes(item));
        }
        return item;
    }).sort(compareFn);
};
var _default = function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [], action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case "GET_PERSPECTIVES":
            {
                var error = action.error, fetching = action.fetching, response = action.response;
                if (error) {
                    if (fetching) {
                        return state;
                    }
                    return [];
                }
                if (fetching) {
                    return state;
                }
                return response && response.perspectives;
            }
        case "SHOW_SHARED_VIEWPOINTS":
            return (0, _maps.mapSharedViewpointsToPerspectives)(action.sharedViewpointDetails);
        case "TOGGLE_PERSPECTIVE_PIN":
            return transformFn(state, action.emitParams.perspectiveID, function(item) {
                return {
                    pin: !item.pin
                };
            });
        case "CLOSE_PERSPECTIVE":
            return transformFn(state, action.emitParams.perspectiveID, function() {
                return {
                    visible: false
                };
            });
        default:
            return state;
    }
};
