"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var _default = function(fileData, filename, mimeType) {
    var finalFilename = filename.replace(/\|&;\$%@"<>\(\)\+,/g, "_");
    var blob = new Blob([
        fileData
    ], {
        type: mimeType
    });
    var url = window.URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = url;
    link.innerText = " ";
    link.setAttribute("download", finalFilename);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
