// [Vincent] as per what I can see in the old adp-e-desktop-workspace-grid repo, this artifact was
// used to focus on the input element available in projection columns. My understanding is that
// projection is not an MVP1 feature, so I am parking this fix for now.
"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var _default = function(params) {
    // const { props, prevProps, api } = params;
    var props = params.props, prevProps = params.prevProps;
    if (props.domManualFocus && prevProps.domManualFocus !== props.domManualFocus) {
        // const headerPanel = api.rowModel.rootNode.columnController.gridPanel.eHeader;
        var headerPanel = null;
        if (headerPanel && headerPanel.querySelectorAll && props.domManualFocus) {
            var focusedEl = headerPanel.querySelector(props.domManualFocus);
            if (focusedEl) {
                focusedEl.focus();
            }
        }
    }
    return params;
};
