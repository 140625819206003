"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    DATE_ONLY_SUFFIX: function() {
        return DATE_ONLY_SUFFIX;
    },
    default: function() {
        return dateComparator;
    }
});
var DATE_ONLY_SUFFIX = "T00:00:00.000Z";
var monthToComparableNumber = function(date) {
    if (!date) {
        return null;
    }
    if (date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var dayNumber = date.substring(3, 5);
    var monthNumber = date.substring(0, 2);
    return yearNumber * 10000 + monthNumber * 100 + dayNumber;
};
var dateComparator = function(date1, date2) {
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }
    return date1Number - date2Number;
};
