"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    openRowDetailsPanel: function() {
        return openRowDetailsPanel;
    },
    closeRowDetailsPanel: function() {
        return closeRowDetailsPanel;
    },
    setRowDetailsPanelPosition: function() {
        return setRowDetailsPanelPosition;
    }
});
var _endpoints = require("../utils/endpoints");
var openRowDetailsPanel = function(param) {
    var anchorNode = param.anchorNode, queryFilter = param.queryFilter, userInput = param.userInput, viewpointAction = param.viewpointAction, viewpointAttributeMappingID = param.viewpointAttributeMappingID, viewpointFilter = param.viewpointFilter, viewpointID = param.viewpointID;
    return {
        type: "OPEN_ROW_DETAILS_PANEL",
        anchorNode: anchorNode,
        config: viewpointAction,
        api: {
            method: "POST",
            endpoint: "".concat(_endpoints.practitionerServicesURL, "/v1/viewpoint/popup?viewpointID=").concat(viewpointID),
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                queryFilter: queryFilter,
                row: userInput,
                viewpointAction: viewpointAction,
                viewpointAttributeMappingID: viewpointAttributeMappingID,
                viewpointFilter: viewpointFilter,
                viewpointID: viewpointID
            })
        }
    };
};
var closeRowDetailsPanel = function() {
    return {
        type: "CLOSE_ROW_DETAILS_PANEL"
    };
};
var setRowDetailsPanelPosition = function(position) {
    return {
        type: "SET_ROW_DETAILS_POPUP_POSITION",
        position: position
    };
};
