"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    invalidateSession: function() {
        return invalidateSession;
    },
    startSessionValidationPolling: function() {
        return startSessionValidationPolling;
    },
    logOut: function() {
        return logOut;
    },
    navigateToSettings: function() {
        return navigateToSettings;
    },
    navigateBackToSettings: function() {
        return navigateBackToSettings;
    },
    resetNavigationHistory: function() {
        return resetNavigationHistory;
    },
    selectSubscription: function() {
        return selectSubscription;
    },
    loadSubscriptions: function() {
        return loadSubscriptions;
    },
    createSubscription: function() {
        return createSubscription;
    },
    updateSubscription: function() {
        return updateSubscription;
    },
    deleteSubscription: function() {
        return deleteSubscription;
    },
    chatAppSubscriptionNotification: function() {
        return chatAppSubscriptionNotification;
    }
});
var _endpoints = require("../utils/endpoints");
var invalidateSession = function() {
    var response = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, prevAction = arguments.length > 1 ? arguments[1] : void 0;
    var action = {
        type: "INVALIDATE_SESSION",
        api: {
            method: "DELETE",
            endpoint: "".concat((0, _endpoints.baseUrl)(), "/workspace/logout"),
            headers: {
                "Content-Type": "application/json"
            }
        }
    };
    return response.error ? action : prevAction;
};
var startSessionValidationPolling = function() {
    return {
        type: "VALIDATE_SESSION",
        polling: {
            interval: 60000,
            endpoint: "".concat((0, _endpoints.baseUrl)(), "/workspace/session"),
            callback: invalidateSession
        }
    };
};
var logOut = function() {
    return {
        type: "LOGOUT",
        api: {
            method: "DELETE",
            endpoint: "".concat((0, _endpoints.baseUrl)(), "/workspace/logout"),
            headers: {
                "Content-Type": "application/json"
            }
        }
    };
};
var navigateToSettings = function(settingsID) {
    return {
        type: "NAVIGATE_TO_SETTINGS",
        settingsID: settingsID
    };
};
var navigateBackToSettings = function() {
    return {
        type: "NAVIGATE_BACK_TO_SETTINGS"
    };
};
var resetNavigationHistory = function() {
    return {
        type: "RESET_SETTINGS_NAVIGATION_HISTORY"
    };
};
var selectSubscription = function(subscription) {
    return {
        type: "SETTINGS_SELECT_SUBSCRIPTION",
        subscription: subscription
    };
};
var loadSubscriptions = function(chatPersonID) {
    return {
        type: "SETTINS_LOAD_APP_SUBSCRIPTIONS",
        api: {
            method: "GET",
            endpoint: "".concat((0, _endpoints.relayAPI)(), "/user/").concat(chatPersonID, "/apps/subscriptions")
        }
    };
};
var createSubscription = function(subscription) {
    return {
        type: "SETTINGS_CREATE_CHAT_APP_SUBSCRIPTION",
        api: {
            method: "POST",
            endpoint: "".concat((0, _endpoints.relayAPI)(), "/user/").concat(subscription.chatPersonID, "/apps/subscriptions"),
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(subscription)
        }
    };
};
var updateSubscription = function(subscription) {
    return {
        type: "SETTINGS_UPDATE_CHAT_APP_SUBSCRIPTION",
        api: {
            method: "PUT",
            endpoint: "".concat((0, _endpoints.relayAPI)(), "/user/").concat(subscription.chatPersonID, "/apps/subscriptions"),
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(subscription)
        }
    };
};
var deleteSubscription = function(subscription) {
    return {
        type: "SETTINGS_DELETE_CHAT_APP_SUBSCRIPTION",
        api: {
            method: "DELETE",
            endpoint: [
                "".concat((0, _endpoints.relayAPI)(), "/user/").concat(subscription.chatPersonID),
                "/apps/subscriptions/app/".concat(subscription.appCode),
                "/appUserID/".concat(subscription.appUserID), 
            ].join("")
        }
    };
};
var chatAppSubscriptionNotification = function(type, subscription) {
    return {
        type: type,
        subscription: subscription,
        error: false,
        fetching: false
    };
};
