"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var _default = function(params) {
    var props = params.props, prevProps = params.prevProps, api = params.api;
    if (prevProps && props) {
        try {
            var prevFontSize = "adp-grid-custom-font-size-".concat(prevProps.fontSize);
            var currentFontSize = "adp-grid-custom-font-size-".concat(props.fontSize);
            var elements = [
                api.gridPanel.eGui,
                api.gridPanel.headerRootComp.eHeaderContainer,
                api.gridPanel.headerRootComp.ePinnedLeftHeader,
                api.gridPanel.headerRootComp.ePinnedRightHeader
            ];
            var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
            try {
                for(var _iterator = elements[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                    var element = _step.value;
                    element.classList.remove(prevFontSize);
                    element.classList.add(currentFontSize);
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally{
                try {
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                } finally{
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        } catch (e) {
        // ignore
        }
    }
    return params;
};
